

/* Logo */
.logoMargins {
  margin: 10px 0 20px 0 !important;
}

.textGrow {
  flex-grow: 1 !important;
}

/* Media */

@media only screen and (max-width: 500px) {
  .desktop__display {
    display: none !important;
  }

  .priority-2 {
    display: none !important;
  }
}

@media only screen and (min-width: 501px) {
  .mobile__display {
    display: none !important;
  }

}

/* Menu */

.menuSettings {
  flex-grow: 1 !important;
}

@media only screen and (max-width: 500px) {
  .menuSettingsFlex {
    flex-grow: 0 !important;
  }
}

@media only screen and (min-width: 501px) {
  .menuSettingsFlex {
    flex-grow: 1 !important;
  }
}

/* Menu styling */

.menuStyling {
  background-color: white !important;
  color: black !important;
  box-shadow: none !important;
  border-bottom: 2px solid #eee !important;
}

.MuiToolbar-regular {
  min-height: 64px !important;
}

.MuiPickersToolbar-toolbar {
  height: 80px !important;
}