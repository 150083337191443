/* Badge */

.badgeMargin {
    margin-right: 25px !important;
}

/* Border */

.grid-gallery__border--not-selected {
    border: 2px solid white !important;
    /* border-radius: 20px !important; */
    padding: 0px 0px 0px 0px !important;
    margin-bottom: -1px !important;
}

.grid-gallery__border--selected {
    border: 3px solid black !important;
    border-radius: 8px !important;
    padding: 0px 0px 0px 0px !important;
}


.MuiGridListTile-tile {
    height: 100% !important;
    display: block !important;
    overflow: hidden !important;
    position: relative !important;
    border-radius: 5px !important;
}
/* Cursor */
.grid-gallery--cursor--hover {
    cursor: pointer !important;
}

.grid-gallery--cursor--hover:hover {
    opacity: 0.4;
}


/* Gallery image load */
.gallery-image--loading {
    display: inline !important;
    opacity: 0.4;
}

.gallery-circular--loading {
    top: 40% !important;
    width: 60px !important;
    display: inline-block !important;
    height: 60px !important;
    position: absolute !important;
    left: 40% !important;
}

/* Grid gallery */
.grid-gallery__list--size {
    width: 300 !important;
    height: 150 !important;
    margin: 15px !important;
}


.grid-gallery__image--margin {
    margin-bottom: 15px !important;
    /* padding: 9.5px !important; */
}

.gird-gallery__title-bar--no-background {
    background: none !important;
}
