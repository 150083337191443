/* Body */
body {
    background-color: #ecf0f1 !important;
}

/* Border */

.nonSelectedBorder {
    border: 2px solid white !important;
    /* border-radius: 20px !important; */
    padding: 0px 0px 0px 0px !important;
    margin-bottom: -1px !important;
}

.selectedBorder {
    border: 3px solid #E4432F !important;
    border-radius: 8px !important;
    padding: 0px 0px 0px 0px !important;
}

/* Buttons */

@media only screen and (max-width: 500px) {
    .demo__icon-button---margin-between {
        margin-right: 10px !important;
    }
  }
  
  @media only screen and (min-width: 501px) {
    .demo__icon-button---margin-between {
        margin-right: 20px !important;
    }
  
  }

.demo__icon-button---margin-right {
    margin-right: 10px !important;
}

/* Canvas */

.canvas--loading-opacity {
    opacity: 0.4 !important;
}

/* Color picker */
.color {
    width: 39px !important;
    height: 39px !important;
    border-radius: 2px !important;
    /* background: red !important; */
  }

.swatch {
    padding: 5px !important;
    background: #fff !important;
    border-radius: 1px !important;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1) !important;
    display: inline-block !important;
    cursor: pointer !important;
  } 

  .popover {
    position: absolute;
    z-index: 2;
    margin-top: 43px !important;
    margin-left: -53px !important;
  }
  .cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }


/* Grid */

.demo__grid--backgorund-white {
    background-color: white !important;
}

/* Grid gallery */

.controllPanelMarginTop {
    margin-top: 158px !important;
}

.controllPanelPosition {
    cursor: pointer !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    /* padding: 10px !important; */
    min-width: 88px !important;
    position: fixed !important;
    top: 80px !important;
    z-index: 998 !important;
    padding: 6px 16px !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.galleryHover {
    cursor: pointer !important;
}

.galleryHover:hover {
    opacity: 0.4;
}

.gridImageMargin {
    margin-bottom: 15px !important;
    /* padding: 9.5px !important; */
}


.gridListSize {
    width: 300 !important;
    height: 150 !important;
    margin: 15px !important;
}

.titleBar {
    background:  rgba(0, 0, 0, 0.4) 0%!important;
    height: 20% !important;
    /* background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%) !important; */

}


.titleBarNoBackground {
    background: none !important;
}

.whiteBackgroundGrid {
    background-color: white !important;
}

/* Loader */
.canvas--loading-gif {
    margin-top: 50px !important;
    position: absolute !important;
}

/* Paper */

.demo__paper-grid--margin-top {
    margin-top: 60px !important;
}


@media only screen and (max-width: 500px) {
    .demo__paper--padding {
        padding: 0 !important;
    }
  }
  
  @media only screen and (min-width: 501px) {
    .demo__paper--padding {
        padding: 60px 60px 60px 60px !important;
    }
  
  }

/* Toggle group */
@media only screen and (max-width: 500px) {
    .demo__toggleButtonGroup--margin-left {
        margin-left: 20px !important;
    }
  }
  
  @media only screen and (min-width: 501px) {
    .demo__toggleButtonGroup--margin-left {
        margin-left: 50px !important;
    }
  
  }

